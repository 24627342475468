.bg-car {
    width: 578px; height: 386.6px;
    background: url(../images/vehicle_one-fifth.png) 0px 0px;
    background-size: 2890px 386.6px;
}

.bg-camera {
    width: 578px; height: 386.6px;
    background: url(../images/vehicle_one-fifth.png) 578px 0px;
    background-size: 2890px 386.6px;
}

.bg-gnss {
    width: 578px; height: 386.6px;
    background: url(../images/vehicle_one-fifth.png) 1156px 0px;
    background-size: 2890px 386.6px;
}

.bg-lidar {
    width: 578px; height: 386.6px;
    background: url(../images/vehicle_one-fifth.png) 1734px 0px;
    background-size: 2890px 386.6px;
}

.bg-radar {
    width: 578px; height: 386.6px;
    background: url(../images/vehicle_one-fifth.png) 2312px 0px;
    background-size: 2890px 386.6px;
}