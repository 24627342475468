.App {
  font-family: sans-serif;
}

a {
  color: white;
}

.custom_ul {
  margin: 0;
  list-style-type: none;
  font-size: larger;
}
.custom_ul p {
  font-size: large;
  margin-top: 5px;
  margin-bottom: 2px;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #33363a;
  color: #bdbdbd;
  width: 600px;
  height: 300px;
}

.amplify-authenticator {
  background: #000000;
  min-width: 10rem;
}

:root {
  --amplify-primary-color: #7bcedb;
  --amplify-secondary-color: #fff;
  --amplify-secondary-contrast: #25282c;
  --amplify-primary-contrast: #000;
  --amplify-font-family: "Inter";
  --amplify-background-color: #151719;
  --amplify-primary-tint: #acadff;
  --amplify-primary-shade: #24e5af;
}

.amplify-sign-out {
  display: block;
  width: 50px;
  padding-top: 50px;
  float: left;
}

.edina_tm_universal_box_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  z-index: -100;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  padding-bottom: 3vh;
}
.edina_tm_universal_box_wrap .bg_wrap {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
}
.edina_tm_universal_box_wrap .overlay_image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 15;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.edina_tm_universal_box_wrap .overlay_video {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 15;
  overflow: hidden;
}
.edina_tm_universal_box_wrap .overlay_video video {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  object-fit: cover;
}
.edina_tm_universal_box_wrap .overlay_color {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
}
.edina_tm_universal_box_wrap .overlay_color.video {
  background-color: rgba(0, 0, 0, 0.7);
}

.edina_tm_universal_box_wrap .content {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  z-index: 25;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.edina_tm_universal_box_wrap .content.hero {
  height: 40vh;
  overflow: hidden;
}

.inner {
  display: inline-block;
}

.innerText {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  position: relative;
  /*left: 22%;*/
}

.leaflet-container {
  width: 100%;
  height: 396px;
}

.general-container {
  position: relative;
  left: 17%;
  float: left;
}

.about {
  font-size: 32px;
}

.port_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.port_figure {
  z-index: 1;
  display: block;
  justify-content: center;
  min-width: 300px;
  overflow: hidden;
  margin: 5px 5px 5px 5px;
  animation: show 1s ease;
}

.port-button {
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 120px;
  font-size: 10px;
  color: #9ca9b3;
}

.table_overflow {
  display: flex;
  overflow-x: auto;
}

td {
  white-space: nowrap;
}

.tooltipHeader {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 0;
}
